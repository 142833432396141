.section-container{
    padding: 25px;
    align-items: center;
    justify-content: space-around;
    background-color: #020104;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    margin-top: 2.5rem;
    border-radius: 10px;
    border: 2px solid #c592ff;
}

.section-container:hover{
    box-shadow: 0 0 25px rgba(197, 146, 255, 0.678);
    background-color: rgba(15, 11, 20, 0.479);
}
  